import { map, filter } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    totalRateCount: 0,
    averageRate: 0,
    fiveCount: 0,
    fourCount: 0,
    threeCount: 0,
    twoCount: 0,
    oneCount: 0,
}

const slice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getReviewsSuccess(state, action) {
            console.log(action.payload);
            state.isLoading = false
            state.totalRateCount = action.payload.totalRateCount
            state.averageRate = action.payload.averageRate
            state.fiveCount = action.payload.fiveCount
            state.fourCount = action.payload.fourCount
            state.threeCount = action.payload.threeCount
            state.twoCount = action.payload.twoCount
            state.oneCount = action.payload.oneCount
        },
    }
})

// Reducer
export default slice.reducer

// Actions
export const { onToggleFollow, deleteOrder } = slice.actions

export function getReviewData(data) {
    return async dispatch => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.post('/order/getReviewData',data)
            console.log("response", response);
            dispatch(slice.actions.getReviewsSuccess(response.data.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}
