import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices
import mailReducer from './slices/mail'
import notificationReducer from './slices/notification'
import chatReducer from './slices/chat'
import blogReducer from './slices/blog'
import userReducer from './slices/user'
import resultReducer from './slices/result'
import orderReducer from './slices/order'
import frontendReducer from './slices/frontend'
import productReducer from './slices/product'
import calendarReducer from './slices/calendar'
import kanbanReducer from './slices/kanban'
import reviewReducer from "./slices/review";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
}

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  order: orderReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  result: resultReducer,
  product: persistReducer(productPersistConfig, productReducer),
  lab: frontendReducer ,
  notification: notificationReducer,
  review: reviewReducer,
})

export { rootPersistConfig, rootReducer }
