import faker from "faker";
import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { set, sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
} from "@mui/material";
// utils
import { mockImgAvatar } from "../../utils/mockImages";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";
import { MIconButton } from "../../components/@material-extend";
import { useSelector, useDispatch } from "../../redux/store";
import {
  getNotification,
  getNotificationMessage,
  getReadMessage,
} from "../../redux/slices/notification";

import useAuth from "../../../src/hooks/useAuth";


// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: "Your order is placed",
    description: "waiting for shipping",
    avatar: null,
    type: "order_placed",
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.findName(),
    description: "answered to your comment on the Minimal",
    avatar: mockImgAvatar(2),
    type: "friend_interactive",
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new message",
    description: "5 unread messages",
    avatar: null,
    type: "chat_message",
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "You have new mail",
    description: "sent from Guido Padberg",
    avatar: null,
    type: "mail",
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: "Delivery processing",
    description: "Your order is being shipped",
    avatar: null,
    type: "order_shipped",
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification && notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification && notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="/static/icons/ic_notification_package.svg"
      //   />
      // ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="/static/icons/ic_notification_shipping.svg"
      //   />
      // ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="/static/icons/ic_notification_mail.svg"
      //   />
      // ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      // avatar: (
      //   <img
      //     alt={notification.title}
      //     src="/static/icons/ic_notification_chat.svg"
      //   />
      // ),
      title,
    };
  }
  return {
    // avatar: <img alt={notification.title} src={notification.avatar} />,
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
  // const { title } = renderContent(notification);
  const dispatch = useDispatch();

  const { order } = useSelector((state) => state.order);
  const orders = order && order.length > 0 && order.map((c) => c.uid);
  // const notificationList = useSelector(({ state }) => state.notification);

  // console.log(notificationList,order, order.uid, "order");
  // useEffect(
  //   (data) => {
  //     dispatch(getNotification(data));
  //     dispatch(getNotificationMessage(data));
  //   },
  //   [dispatch]
  // );
  return (
    <ListItem
      button
      to={`/dashboard/order/detail/${notification.orderId}`}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{"jgg"}</Avatar>
      </ListItemAvatar> */}
      {/* <Tooltip title={notification.message}> */}

      <ListItemText
        primary={notification.message}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.createdAt))}
          </Typography>
        }
      />
      {/* </Tooltip> */}
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const { user } = useAuth();

  const [open, setOpen] = useState(false);
  const { notification, count } = useSelector((state) => state.notification);

  const id = notification.map((c) => c._id);
  console.log(notification, id, "notification");
  const [notifications, setNotifications] = useState(notification);
  const totalUnRead = notifications.filter((item) => item.isUnRead === true)
    .length;
  const dispatch = useDispatch();

  useEffect(
    (data) => {
      setInterval(() => {
        dispatch(getNotification(data));
      }, 10000);
    },
    [open]
  );
  useEffect(() => {
    dispatch(getNotificationMessage());
  }, [open]);

  useEffect(() => {
    console.log(open, notification, "i m call");
    if (open && notification.length > 0) {
      open && unReadMessage();
    }
  }, [notification]);

  const unReadMessage = () => {
    const unreadMessages = notification
      .filter((item) => user.user_type === "2" ? item.lab_Readstatus:item.customer_Readstatus === "unread")
      .map((e) => e._id);
    let data = {
      Id: [...unreadMessages],
    };
    console.log(data, unreadMessages, notification, "unreadMessages");
    dispatch(getReadMessage(data));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notification.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? "primary" : "default"}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={count} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {count > 0 && (
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                You have {count || 0} unread messages
              </Typography>
            )}
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notification.length > 0 &&
              notification?.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Before that
              </ListSubheader>
            }
          >
            {notification.length > 0 &&
              notification?.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
          </List> */}
        </Scrollbar>

        <Divider />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
