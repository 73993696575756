import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

import { useSnackbar } from "notistack";
import { MIconButton } from "src/components/@material-extend";
// import { MIconButton } from "src/components/@material-extend";

// import { MIconButton } from '../../@'

// import { MIconButton } from '../../@material-extend'

// MIconButton
// ----------------------------------------------------------------------

// const { enqueueSnackbar, closeSnackbar } = useSnackbar()

function objFromArray(array, key = "id") {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  mails: { byId: {}, allIds: [] },
  labels: [],
  customerList: [],
  selectedUser: {},
  pageNumber: null,
  viewMessages: {
    data: [],
  },
};

const slice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },
    getViewSuccess(state, action) {
      state.isLoading = false;
      state.viewMessages = action.payload;
    },
    setSelectedUser(state, action) {
      state.isLoading = false;
      state.selectedUser = action.payload;
    },
    setPageNumber(state, action) {
      console.log(action,"action")
      state.isLoading = false;
      state.selectedUser = action.payload;
    },

    // getsendMessageSuccess(state, action) {
    //   state.isLoading = false;
    //   state.mails = action.payload;
    // },
    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;

      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail.id] = mail;
      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },
    // GET CUSTOMER LIST
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customerList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabels(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/messages/getLabChatList";
      if (data.customer_id) url = "/messages/getCustomerChatList";

      const response = await axios.post(url, data);
      dispatch(slice.actions.getLabelsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setMailListUser(data) {
  return async (dispatch) => {
    dispatch(slice.actions.setSelectedUser(data));
  }
}

export function setPageNumbersCount(data){
  console.log(data,"data")
  return async (dispatch) => {
    dispatch(slice.actions.setPageNumber(data))
  }
}
export function sendMessage(data, callBack) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/messages/addMessage", data);
      callBack && callBack(response);

      // dispatch(slice.actions.getLabelsSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMails(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/messages/getLabChatList";
      if (data.customer_id) url = "/messages/getCustomerChatList";

      const response = await axios.post(url, data);
      dispatch(slice.actions.getLabelsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export function getMails(params) {
//   return async dispatch => {
//     dispatch(slice.actions.startLoading())
//     try {
//       const response = await axios.post('/api/mail/mails', { params })
//       dispatch(slice.actions.getMailsSuccess(response.data.mails))
//     } catch (error) {
//       dispatch(slice.actions.hasError(error))
//     }
//   }
// }

// ----------------------------------------------------------------------

export function getMail(mailId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/mail/mail", {
        params: { mailId },
      });
      dispatch(slice.actions.getListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMailUser(data, callBack) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/messages/getChatWithFilter", data);
      //  callBack && callBack(response)
      dispatch(slice.actions.getViewSuccess({...data, ...response.data}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomerList(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/auth/customerListForLab";
      if (data.customer_id) url = "/auth/labListForCustomer";

      const response = await axios.get(url, data);
      dispatch(slice.actions.getCustomerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
