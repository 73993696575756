// material
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function SeoIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox="0 0 480 480"
      >
        <g id="freepik--Floor--inject-64">
          <path
            d="M457.73,424.67c0,.14-93,.26-207.72.26s-207.74-.12-207.74-.26,93-.26,207.74-.26S457.73,424.52,457.73,424.67Z"
            style={{ fill: "#263238" }}
          />
        </g>
        <g id="freepik--Map--inject-64">
          <polygon
            points="388.5 55 246.15 55 246.15 64.5 379 64.5 379 396.5 246.15 396.5 246.15 406 388.5 406 388.5 55"
            style={{ fill: "#455a64" }}
          />
          <polygon
            points="246.15 64.5 246.15 74 246.15 387 246.15 396.5 379 396.5 379 64.5 246.15 64.5"
            style={{ fill: "#fff" }}
          />
          <polygon
            points="106 396.5 106 64.5 246.15 64.5 246.15 55 96.5 55 96.5 406 246.15 406 246.15 396.5 106 396.5"
            style={{ fill: "#263238" }}
          />
          <polygon
            points="106 64.5 106 396.5 246.15 396.5 246.15 387 246.15 74 246.15 64.5 106 64.5"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M347.48,74.69c2.53.84,5,1.77,7.46,2.74V64.5H275.09l1.38,0C297.49,65.25,324.56,67,347.48,74.69Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M368.06,64.5V83.17c3.63,1.72,7.26,3.53,10.94,5.37V69.08c-3.41-1.58-6.9-3.12-10.49-4.58Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M146.94,352.65a105.53,105.53,0,0,0,20.18-9.56l-43-31c-6.5,3.07-13.31,6.26-18.08,9.65v26.19C116.28,355.48,133.09,357.39,146.94,352.65Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M221.15,274.63a62.18,62.18,0,0,1-.2-19l-20.95-5c-10-2.39-20.8,1.68-23.17,8.72-5.81,17.3-21.24,34.24-39.72,46a86,86,0,0,1-8.24,4.47l20.45,14.74,73.13-44.42A48.51,48.51,0,0,1,221.15,274.63Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M236.74,303.89a57.37,57.37,0,0,1-12.81-19.41l-70.61,42.9,18.1,13.05c6.84-4.35,13.32-9.06,20.16-13.54A243.89,243.89,0,0,1,236.74,303.89Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M308.49,276.58l-81.9-19.65a58.12,58.12,0,0,0,.32,17.23A51.92,51.92,0,0,0,241.85,302a314.49,314.49,0,0,1,65.8-16.19C314.66,284.81,315.27,278.13,308.49,276.58Z"
            style={{ fill: "#f5f5f5" }}
          />
          <path
            d="M142.3,244.78l3.09-18.33.31-1.69c-.49-1-1-1.93-1.45-2.89-4.46,1.19-9.41,1.07-14.12,1.63s-9.74,2.15-11.32,5.23c-1,1.89-.45,4,.09,5.94q1.23,4.56,2.47,9.11c.68,2.54,1.42,5.16,3.52,7.3a11.56,11.56,0,0,0,9.64,3.08,8.93,8.93,0,0,0,6-3.78A14.32,14.32,0,0,0,142.3,244.78Z"
            style={{ fill: "#f5f5f5" }}
          />
          <polygon
            points="317.08 207.48 368.51 222.76 379 215.43 379 194.18 344.64 183.98 317.08 207.48"
            style={{ fill: "#e6e6e6" }}
          />
          <polygon
            points="350.65 178.86 379 187.28 379 165.39 367.97 164.09 350.65 178.86"
            style={{ fill: "#e6e6e6" }}
          />
          <path
            d="M368.06,83.17V64.5H354.94v80L187.27,111.73l9.09-47.23H183.48l-8.82,45.79L106,107.46v8.73L173,119l-16.72,86.8-45.82,5.69L106,195.56v28.85a113.79,113.79,0,0,0,3.66,23.19,28.82,28.82,0,0,0,4.9,10.92c4.61,5.83,13.37,10,22.93,11a55,55,0,0,1-12.32,12.67c-5.54,4.12-12.11,7.48-19.17,10.67v10.35l3.58-1.54,14.5,10.45,43,31,13,9.35c-12.61,7-25.11,12.56-38.28,14.06-3.13.35-6.86.54-10.85.74a184,184,0,0,0-25,2.51v9c.66-.15,1.31-.29,2-.42a161.47,161.47,0,0,1,23.61-2.41c4.25-.22,8.27-.42,12-.84,23-2.62,42.29-14.37,62.68-26.81,21.24-13,45.32-27.67,76.49-32.09,33.08-4.7,69.95,3.39,96.23,21.12l0,0v-11.9a156.35,156.35,0,0,0-50-17c9.51-6.56,16.27-15,18.84-24.05.15-.53.28-1.06.41-1.59a26.09,26.09,0,0,0,.66-5.78,24.91,24.91,0,0,0-.18-3L379,265v-9.51l-32.88,9.81A35.94,35.94,0,0,0,338.45,255L379,236v-4.8L334.8,251.9A63,63,0,0,0,311.18,241L273,231.71l91-76.6,15,1.55v-8.81l-10.94-1.13Zm-182.47,37.3,47.33,9.26-2.58,91.61L169,206.43ZM119.68,256.64c-2.39-3-3.48-6.62-4.31-9.75a110.86,110.86,0,0,1-3.71-26.75l42.88-5.32-2.7,14c-2.62,13.6-5.55,26.13-11.89,36.85C131.55,265.2,123.68,261.72,119.68,256.64Zm9.19,53.12-14.36-10.35A112.52,112.52,0,0,0,134.58,288c20.23-15,25.52-35.19,29.92-58l2.83-14.71,60.93,14.82a102.9,102.9,0,0,0-7.31,25.5,62.18,62.18,0,0,0,.2,19,48.51,48.51,0,0,0,1.3,5.45L149.32,324.5Zm69.19,32c-4.55,2.78-9,5.51-13.51,8.11l-13.13-9.47-18.1-13.05,70.61-42.9a57.37,57.37,0,0,0,12.81,19.41,67.37,67.37,0,0,0,14.33,10.94C230.61,321.93,213.62,332.29,198.06,341.79ZM306.89,249.2c19.84,4.82,31.93,18.38,28.74,32.22-.1.43-.21.86-.33,1.28-2.71,9.49-11.09,18.24-22.67,23.82a152.86,152.86,0,0,0-55.73,6.41,62.33,62.33,0,0,1-15-11,51.92,51.92,0,0,1-14.94-27.8,58.12,58.12,0,0,1-.32-17.23,98.54,98.54,0,0,1,7.17-25.52Zm-11-50.85L260,228.56l-23.92-5.82,2.58-91.88,57.22,11.19Zm6.56-5.52v-49.5l47.71,9.34Z"
            style={{ fill: "#e0e0e0" }}
          />
          <polygon
            points="162.32 126.05 120.98 124.74 120.98 170 154.99 170 162.32 126.05"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="114.43"
            y="178.52"
            width="39.36"
            height="18.46"
            style={{ fill: "#ebebeb" }}
          />
          <polygon
            points="256.55 114.34 346.2 130.18 346.2 83.38 289.35 83.38 289.35 104.68 256.55 104.68 256.55 114.34"
            style={{ fill: "#ebebeb" }}
          />
          <polygon
            points="234.69 110.83 234.69 72.02 207.11 72.02 202.29 104.66 234.69 110.83"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="247.8"
            y="70.6"
            width="28.43"
            height="19.88"
            style={{ fill: "#e0e0e0" }}
          />
          <polygon
            points="293.9 227.24 340.95 241.99 361.56 227.6 311.07 212.61 293.9 227.24"
            style={{ fill: "#e6e6e6" }}
          />
          <rect
            x="195.33"
            y="135.92"
            width="26.24"
            height="58.22"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="177.84"
            y="191.3"
            width="6.56"
            height="11.36"
            style={{ fill: "#e0e0e0" }}
          />
          <polygon
            points="287.16 149.22 247.8 141.04 247.8 170.74 247.8 175.68 287.16 175.68 287.16 149.22"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="243.43"
            y="192.72"
            width="8.75"
            height="17.04"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="256.55"
            y="192.72"
            width="8.75"
            height="17.04"
            style={{ fill: "#ebebeb" }}
          />
          <rect
            x="269.67"
            y="192.72"
            width="8.75"
            height="17.04"
            style={{ fill: "#ebebeb" }}
          />
          <polygon
            points="118.8 64.5 106 64.5 106 99.73 118.8 100.19 118.8 64.5"
            style={{ fill: "#e6e6e6" }}
          />
          <polygon
            points="164.51 101.73 170.59 64.5 129.73 64.5 129.73 100.49 164.51 101.73"
            style={{ fill: "#e6e6e6" }}
          />
          <polygon
            points="379 317.25 379 299.66 370.89 313.49 379 317.25"
            style={{ fill: "#f0f0f0" }}
          />
          <path
            d="M182.78,350C203.5,340,217,319.9,237.11,308.9a41.06,41.06,0,0,1,16.77-5.36c5.25-.36,10.29.08,15.43-1.39,7.19-2,11.56-6.67,14.72-13.37,3.89-8.26,6.82-19.11,3.09-27.87a36.82,36.82,0,0,0-18.19-18.79c-4.46-2.1-9.25-3.35-13.81-5.22-3-1.22-6.54-2.55-9-4.73-1.86-1.67-1.12-2.74.07-4.92a55.69,55.69,0,0,1,5.46-7.68c3.69-4.62,8.11-9.11,10.82-14.43,1.9-3.72,2.24-7.92,2.3-12,.2-13.34.7-26.65,1-40,0-1.47-2.19-1.45-2.28,0-.73,11.86-1.43,23.82-1.18,35.71.09,4.34.34,9.11-1.13,13.28-1.94,5.51-6.61,10.11-10.23,14.55a64,64,0,0,0-7.63,10.84c-.91,1.78-1.46,3-.37,4.79,2.43,4,9.12,6,13.25,7.59,5.21,2,10.52,3.4,15.38,6.29a33.75,33.75,0,0,1,11.18,11.27c4.18,6.62,4.66,12.88,2.88,20.43-2.3,9.77-6.61,19.55-16.88,22.58-5,1.48-9.87,1.09-14.95,1.45a39.27,39.27,0,0,0-12.54,3.19c-22.8,9.73-37.5,32.17-59,44.19-.5.28-.06,1,.44.76Z"
            style={{ fill: "#263238" }}
          />
          <path
            d="M196.66,289.41c1.57-13.53-15.84-17.8-22.5-7.86-6.09,9.1,10.58,35.85,10.58,35.85h0m3-22.78a6.57,6.57,0,1,1,1.66-9.14A6.58,6.58,0,0,1,187.72,294.62Z"
            style={{ fill: "#37474F" }}
          />
        </g>
        <g id="freepik--Plants--inject-64">
          <path
            d="M452.88,379.71a8.92,8.92,0,0,0-6.21,3.24,16,16,0,0,0-3.12,6.43c-1.16,4.5-.37,9.79.94,14.25,4.85-1.78,7.8-7,9.14-9.73,2.1-4.32,3.28-13.42-.75-14.19"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M452.88,379.71a8.92,8.92,0,0,0-6.21,3.24,16,16,0,0,0-3.12,6.43c-1.16,4.5-.37,9.79.94,14.25,4.85-1.78,7.8-7,9.14-9.73,2.1-4.32,3.28-13.42-.75-14.19"
            style={{ opacity: "0.2" }}
          />
          <path
            d="M437.55,409.13a7.65,7.65,0,0,0,.29-7.66,10.17,10.17,0,0,0-6.15-4.86,4.27,4.27,0,0,0-3.59.21,3.25,3.25,0,0,0-1.23,3.07,6.57,6.57,0,0,0,1.42,3.13c2.39,3.18,5.29,5.82,9.26,6.11"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M437.51,424.57a3.85,3.85,0,0,0,.24-.95c.12-.61.27-1.51.43-2.62a32.61,32.61,0,0,0,.23-8.77,16.47,16.47,0,0,0-3.29-8.12,10.77,10.77,0,0,0-1.92-1.87,2.76,2.76,0,0,0-.83-.51,23,23,0,0,1,2.46,2.61,17.23,17.23,0,0,1,3.06,8,37.62,37.62,0,0,1-.08,8.66A29.74,29.74,0,0,0,437.51,424.57Z"
            style={{ fill: "#263238" }}
          />
          <path
            d="M451.38,386.55a9.27,9.27,0,0,0-.72,1.45c-.43.94-1,2.31-1.76,4-1.45,3.4-3.38,8.14-5.38,13.43s-3.68,10.12-4.85,13.63c-.58,1.76-1,3.19-1.34,4.18a8.43,8.43,0,0,0-.41,1.55,11,11,0,0,0,.6-1.49c.39-1.06.89-2.44,1.5-4.12,1.25-3.48,3-8.29,5-13.57s3.87-10,5.23-13.48c.65-1.65,1.19-3,1.61-4.07A10.1,10.1,0,0,0,451.38,386.55Z"
            style={{ fill: "#263238" }}
          />
          <path
            d="M442.59,413.55a17.89,17.89,0,0,1,14.35-7.79c1.44,0,3.15.33,3.71,1.65s-.38,2.81-1.42,3.81a15.25,15.25,0,0,1-16.71,2.71"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M454.46,409.3a3.13,3.13,0,0,0-.94,0,7.39,7.39,0,0,0-1.09.14,12,12,0,0,0-1.42.29,18.67,18.67,0,0,0-3.56,1.3,21.94,21.94,0,0,0-3.93,2.49,22.42,22.42,0,0,0-3.24,3.33,19.15,19.15,0,0,0-2,3.23,11,11,0,0,0-.58,1.33,6.35,6.35,0,0,0-.35,1,3.37,3.37,0,0,0-.21.91c.1,0,.47-1.25,1.47-3.12a21.32,21.32,0,0,1,2.05-3.09,21.15,21.15,0,0,1,7-5.69,20.65,20.65,0,0,1,3.45-1.37C453.14,409.5,454.47,409.4,454.46,409.3Z"
            style={{ fill: "#263238" }}
          />
          <path
            d="M62,202.36c14-6.18,20.69,37.87,21.59,41.3s3.28,3.84,7.35-1.3,7.58-3.15,7.58-3.15c7.66,4.67-2.73,31.85-2.63,34.06s-1.54,5.39,6.42.89,14.64-6.9,15.8,0c1.06,6.28-7.07,24.41-7,27s8.29-3.92,11.75-6.08c4.48-2.78,9.26-2.59,10.1,3,1.09,7.33-13.48,28.77-13.48,28.77l-7,10.92-12.79-3.45s-25-7.06-28.91-13.34c-3-4.83.49-8.08,5.69-8.88,4-.61,14.47-1,12.88-3s-19.55-10.47-22.84-15.93c-3.6-6,3-8.52,12-10.28s5.67-3.11,4.3-4.85-26.93-15.64-24.14-24.17c0,0,1.37-3.78,7.8-2.53s8-.6,6.42-3.8-25.06-41-10.44-45.42"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M119.91,358.22c-.27.1-12.22-31.91-26.68-71.49s-26-71.75-25.72-71.85,12.22,31.91,26.69,71.5S120.18,358.12,119.91,358.22Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M86.69,267.35c-.26-.1.81-3.71,2.71-7.92s3.86-7.43,4.11-7.29-1.29,3.52-3.16,7.71S87,267.44,86.69,267.35Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M69.48,260.11c.06-.27,4.12.53,8.82,2.44s8.2,4.13,8.06,4.36-3.8-1.53-8.44-3.4S69.4,260.4,69.48,260.11Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M97.17,296.55c-.22-.19,2.77-4,6.68-8.61s7.25-8.11,7.47-7.93-2.77,4-6.68,8.61S97.39,296.74,97.17,296.55Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M74,291.86c0-.27,5.47.18,12.06,1.48s11.8,2.92,11.73,3.19-5.37-.89-11.93-2.17S73.93,292.16,74,291.86Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M81.16,319.84c0-.29,5.74.31,12.73,1.34s12.62,2.09,12.57,2.37-5.73-.32-12.72-1.34S81.12,320.12,81.16,319.84Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M107.09,323.08c-.19-.25,3.68-3.36,7.7-7.92s6.67-8.75,6.93-8.59-2,4.65-6.15,9.28S107.23,323.3,107.09,323.08Z"
            style={{ fill: "#fff" }}
          />
        </g>
        <g id="freepik--speech-bubbles--inject-64">
          <path
            d="M455.23,134.41s0-.23.06-.66.11-1.08.2-1.9c0-.42.13-.89.14-1.41s0-1.09,0-1.7,0-1.28,0-2-.12-1.45-.19-2.25a36.82,36.82,0,0,0-3-11.2,36.29,36.29,0,0,0-9-12.31l-1.64-1.42c-.58-.44-1.19-.86-1.79-1.29a35.81,35.81,0,0,0-3.89-2.4A36.3,36.3,0,0,0,427,92.59a37.54,37.54,0,0,0-5-.78c-.86-.1-1.74-.06-2.62-.09l-1.32,0c-.44,0-.88.07-1.33.1A34.56,34.56,0,0,0,406,94.15a36.48,36.48,0,0,0-17.88,14.77A37,37,0,0,0,383,133.21q.12.84.27,1.65l0,.11-.06.09-11.78,16.36-.24-.4,17.38-2.69.15,0,.09.13a36.3,36.3,0,0,0,21.36,15.05,27.13,27.13,0,0,0,4,.74l2,.25c.66.08,1.32.05,2,.08A36.25,36.25,0,0,0,432.66,162a37.48,37.48,0,0,0,10.74-6.61l2.05-1.93,1.76-2a19.82,19.82,0,0,0,1.53-2c.45-.67,1-1.27,1.33-1.93a37.9,37.9,0,0,0,3.39-6.89l.9-2.64c.18-.78.34-1.44.46-2l.3-1.2c.07-.28.11-.41.11-.41a3.17,3.17,0,0,1-.07.41c-.06.29-.14.7-.25,1.22s-.26,1.21-.43,2l-.87,2.66a37.25,37.25,0,0,1-3.33,7c-.37.67-.88,1.28-1.33,2a19.78,19.78,0,0,1-1.53,2l-1.76,2-2.05,2a37.6,37.6,0,0,1-10.81,6.72A36.59,36.59,0,0,1,418.21,165c-.66,0-1.34,0-2-.07l-2-.25a27.9,27.9,0,0,1-4.09-.74,37.89,37.89,0,0,1-8.05-3,37.31,37.31,0,0,1-13.61-12.17l.24.1-17.38,2.71-.6.09.36-.5c3.81-5.3,7.75-10.77,11.76-16.36l0,.2c-.1-.55-.2-1.11-.28-1.67a37.59,37.59,0,0,1,5.23-24.64,36.94,36.94,0,0,1,18.14-15,35.07,35.07,0,0,1,10.83-2.37c.45,0,.9-.08,1.35-.09l1.34,0c.89,0,1.77,0,2.64.09a38.2,38.2,0,0,1,5.1.81A35.34,35.34,0,0,1,440.23,98c.61.44,1.22.87,1.81,1.31l1.64,1.45a36.18,36.18,0,0,1,12,23.76c.06.8.12,1.56.17,2.27s0,1.38,0,2,0,1.19,0,1.71-.12,1-.16,1.41c-.11.82-.2,1.46-.26,1.9S455.23,134.41,455.23,134.41Z"
            style={{ fill: "#263238" }}
          />
          <polygon
            points="431.04 114.11 435.42 147.36 402.91 147.36 407.64 114.11 431.04 114.11"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M410.29,118.93a15.32,15.32,0,0,0,1.65,3.15,9,9,0,0,0,2.83,2.5,9.37,9.37,0,0,0,4.49,1.08,9.56,9.56,0,0,0,4.5-1,7.76,7.76,0,0,0,2.79-2.52,13.65,13.65,0,0,0,1.49-3.22,2.12,2.12,0,0,1-.13,1,8.89,8.89,0,0,1-1.05,2.45,7.84,7.84,0,0,1-2.87,2.74,9.64,9.64,0,0,1-4.74,1.09,9.51,9.51,0,0,1-4.71-1.18,8.78,8.78,0,0,1-2.9-2.71,9.5,9.5,0,0,1-1.17-2.4A2.32,2.32,0,0,1,410.29,118.93Z"
            style={{ fill: "#fff" }}
          />
          <polyline
            points="229.74 38.39 229.74 117.86 209.48 97.6 61.96 97.6 61.96 38.39 229.74 38.39"
            style={{ fill: "#37474F" }}
          />
          <circle
            cx="189.04"
            cy="70.05"
            r="22.36"
            style={{ fill: "#37474F" }}
          />
          <circle cx="189.04" cy="70.05" r="22.36" style={{ opacity: "0.2" }} />
          <path
            d="M77.47,62.79c0-.18,17.3-.33,38.64-.33s38.64.15,38.64.33-17.29.33-38.64.33S77.47,63,77.47,62.79Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M77.47,71.3c0-.18,17.3-.33,38.64-.33s38.64.15,38.64.33-17.29.32-38.64.32S77.47,71.48,77.47,71.3Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M114.59,79.67c0-.18,9-.33,20.08-.33s20.08.15,20.08.33-9,.32-20.08.32S114.59,79.85,114.59,79.67Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M113.05,54c0-.18,9.34-.33,20.85-.33s20.85.15,20.85.33-9.33.33-20.85.33S113.05,54.22,113.05,54Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M191,69.67a5.54,5.54,0,0,0,3.21-5.19,5.21,5.21,0,1,0-10.4.14,5.54,5.54,0,0,0,3.41,5.12c-8,1.35-7.46,10-7.46,10l18.93-.26S198.93,70.72,191,69.67Z"
            style={{ fill: "#fff" }}
          />
          <polyline
            points="196.74 151.78 196.74 184.38 188.43 176.07 127.91 176.07 127.91 151.78 196.74 151.78"
            style={{ fill: "#37474F" }}
          />
          <path
            d="M157.91,159.57c0-.15,6.71-.26,15-.26s15,.11,15,.26-6.71.26-15,.26S157.91,159.71,157.91,159.57Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M178.16,166.34a16.93,16.93,0,0,1,4.21-.26,17,17,0,0,1,4.22.26,17.57,17.57,0,0,1-4.22.26A17.52,17.52,0,0,1,178.16,166.34Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M176.17,168.21c-.14,0-.38-.43-.66-1s-.51-1-.39-1.12.55.28.85.89S176.31,168.18,176.17,168.21Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M160.6,166.1a33,33,0,0,1,5.8-.26,32.85,32.85,0,0,1,5.79.26,31.74,31.74,0,0,1-5.79.26A31.9,31.9,0,0,1,160.6,166.1Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M159.2,168.09c-.13.07-.49-.37-.82-1s-.48-1.16-.35-1.23.49.38.82,1S159.33,168,159.2,168.09Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M142.35,166a35,35,0,0,1,6.08-.26,35.14,35.14,0,0,1,6.09.26,36.37,36.37,0,0,1-6.09.26A36.2,36.2,0,0,1,142.35,166Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M140.84,167c-.12.08-.49-.29-.83-.82s-.51-1-.39-1.09.49.28.83.81S141,167,140.84,167Z"
            style={{ fill: "#fff" }}
          />
          <path
            d="M134,165.52c0-.15.81-.26,1.81-.26s1.82.11,1.82.26-.82.26-1.82.26S134,165.66,134,165.52Z"
            style={{ fill: "#fff" }}
          />
        </g>
      </svg>
    </Box>
  );
}
