import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
// utils
import axios from '../utils/axios'
import { isValidToken, setSession } from '../utils/jwt'

// ----------------------------------------------------------------------
let accessTokenUser = localStorage.getItem('accessToken');

let userData = JSON.parse(localStorage.getItem('user'));

const initialState = {
  isAuthenticated:accessTokenUser ? true :false,
  isInitialized:accessTokenUser ? true : false,
  user:userData ? userData : null
}
//console.log("initialState", initialState);
const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: state => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  UPDATE: (state, action) => {
    const user = action.payload

    return {
      ...state,
      user
    }
  }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  labRegister: () => Promise.resolve(),
  resetEmail: () => Promise.resolve(),
  resetPassword: () => Promise.resolve()
})

AuthProvider.propTypes = {
  children: PropTypes.node
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  console.log("state =========>", state)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')
        const userString = window.localStorage.getItem('user')
       // console.log("userString", userString);
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken)

          const user = userString ? JSON.parse(userString) :''
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (email, password) => {
    const response = await axios.post('/auth/login', {
      email,
      password
    })
    const { accessToken, user } = response.data
    window.localStorage.setItem('user', JSON.stringify(user))
    setSession(accessToken)
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })
  }

  const register = async payload => {
    const response = await axios.post('/auth/register', payload)

    const { accessToken, user } = response.data

    window.localStorage.setItem('user', JSON.stringify(user))

    window.localStorage.setItem('accessToken', accessToken)
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  }

  const labRegister = async payload => {
    const response = await axios.post('/auth/laboratory/register', payload)

    const { accessToken, user } = response.data

    window.localStorage.setItem('user', JSON.stringify(user))

    window.localStorage.setItem('accessToken', accessToken)
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  }

  const uploadProfileImage = async (payload, callback) => {

    //console.log("payload", payload);
    
    const response = await axios.post('/auth/profile', payload)
    console.log(response)

    const { data, success } = response.data
    if (success == true) {
      window.localStorage.setItem('user', JSON.stringify(data))
      dispatch({
        type: 'UPDATE',
        payload: data
      })

      callback && callback(data)
    }
  
  }

  const uploadProfileSignatureImage = async (payload, callback) => {

    //console.log("payload", payload);
    
    const response = await axios.post('/auth/signature', payload)
    console.log(response)

    const { data, success } = response.data
    if (success == true) {
      window.localStorage.setItem('user', JSON.stringify(data))
      dispatch({
        type: 'UPDATE',
        payload: data
      })

      callback && callback(data)
    }
  
  }

  const logout = async () => {
    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  const resetEmail = async email => {
    await axios.post('/auth/forgotpassword', {
      email
    })
  }

  const resetPassword = async (password, token) => {
    await axios.put(`/auth/resetpassword/${token}`, {
      password
    })
  }

  const updateProfile = async(value) => {
    console.log("value", value);
    await axios.post('/auth/update', {
      value
    })

  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        labRegister,
        resetPassword,
        resetEmail,
        updateProfile,
        uploadProfileImage,
        uploadProfileSignatureImage
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
