import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

function objFromArray(array, key = "id") {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  notification: [],
  count:0,
  NotificationMessage: [],
  unRead:false
};

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNotifiSuccess(state, action) {
      state.isLoading = false;
      state.count = action.payload.data;
    },
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notification = action.payload;
    },
    getReadSuccess(state, action) {
      state.isLoading = false;
      state.unRead = action.payload.data;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotification(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/messages/getUnreadMessageCount";
      // if (data.customer_id) url = "/messages/getCustomerChatList";

      const response = await axios.post(url, data);
      dispatch(slice.actions.getNotifiSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotificationMessage(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/messages/getNotificationMessage";
      // if (data.customer_id) url = "/messages/getCustomerChatList";

      const response = await axios.post(url, payload);
      const { data, status } = response;

      dispatch(slice.actions.getNotificationSuccess(data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReadMessage(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = "/messages/UpdateNotificationMessage";
      // if (data.customer_id) url = "/messages/getCustomerChatList";

      const response = await axios.post(url, data);
      console.log(response,"responsee")
      dispatch(slice.actions.getReadSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}